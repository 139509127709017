// mixin.js
import AMapLoader from '@amap/amap-jsapi-loader';

export const mapMixin = {
  data() {
    return {
      mapPlugins: ["AMap.Scale", "AMap.ToolBar", 'AMap.MarkerClusterer',"AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker','AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow','AMap.IndexCluster','AMap.MassMarks'],
      mapStyle:"blue",
      map:{},
      viewMode:"2D",

    };
  },
  mounted() {
    setTimeout(()=>{
      this.initAMap();
    },500)
  
    
    // for (var i = 0; i < 1000; i++) {
    //     var longitude = Math.random() * 360 - 180;;
    //     var latitude = Math.random() * 180 - 90;
    //     this.points.push([ longitude,latitude ]);
    //   }

  },
  unmounted() {
    console.log(11)
    // this.map?.destroy();
  },
  methods: {
    initAMap() {
      let that = this;
      window._AMapSecurityConfig = {
        securityJsCode: "a3582e5d0aac159946dd3a69d22edf28",
      };

      AMapLoader.load({
        key: "9fe621bf4b3ff56a966bada6c1121027", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: this.mapPlugins, //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      })
        .then((AMap) => {
          window.map = new AMap.Map(that.$refs["map"], {
            // viewMode:that.viewMode, // 是否为3D地图模式
            // mapStyle: "amap://styles/"+that.mapStyle, //设置地图的显示样式
            zoom: 5.9, // 初始化地图级别
            zooms: [3, 20],
           // center: [111.727327, 40.826057],
            center:[111.421889,45.195607]
          });

          //2、创建省市简易行政区图层
          var distProvince = new AMap.DistrictLayer.Province({
            zIndex: 14, //设置图层层级
            zooms: [2, 15], //设置图层显示范围
            adcode: "150000", //设置行政区 adcode
           //设置数据显示层级，0：显示国家面，1：显示省级，当国家为中国时设置depth为2的可以显示市一级
          });


            // 3、设置行政区图层样式
          distProvince.setStyles({
            "stroke-width": 1, //描边线宽
            "city-stroke":"#23FFF9",
            "province-stroke":"#23FFF9",
            fill: function (data) {
              // 设置区域填充颜色，可根据回调信息返回区域信息设置不同填充色 #23FFF988
              // 回调返回区域信息数据，字段包括 SOC(国家代码)、NAME_ENG(英文名称)、NAME_CHN(中文名称)等
              // 国家代码名称说明参考 https://a.amap.com/jsapi_demos/static/demo-center/js/soc-list.json
              return "#054BA4";
            },
          });

            //4、将简易行政区图层添加到地图
            window.map.add(distProvince);

         // that.loadData(that)

         // EventBus.$emit('scztDataLoaded', this.scztResultData); // 发布事件
         window.map.on('zoomend', () => { // 监听地图缩放结束后的等级

          this.executeConditionRender();
        });
        window.map.on('moveend', () => { // 监听地图中心点的位置变化
            this.executeConditionRender();
        })
        window.map.on('zoomchange', () => {
         
          this.zoomChnage();
        })
        window.map.on('click', e => {
            console.log(e)
        })


        })
        .catch((e) => {
          console.log(e);
        });
    },
 

  },
};

